<template>
  <div>
    <div class="default-layout">
      <OsQRCodeTrap v-if="isKioskMode" />
      <LayoutKioskHeader v-if="isKioskMode" />
      <LayoutHeader v-else />

      <NotificationBanner
        v-if="!isKioskMode"
        class="main__notification-banner"
      />

      <div class="main-container">
        <main class="main">
          <slot />
        </main>
      </div>

      <client-only>
        <transition name="fade">
          <OsChatBubble v-if="!isKioskMode && showChatBubble" />
        </transition>
      </client-only>

      <LayoutKioskFooter v-if="isKioskMode" />

      <LayoutFooter v-else />
    </div>

    <div id="sidepanel-port" />

    <div id="scroll-to-top-port" class="vue-portal-target--scrollToTop" />

    <client-only>
      <FormsContainer v-if="!isKioskMode" />
    </client-only>
  </div>
</template>

<script setup>
import { useSettingsStore } from '~/stores/settingsStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useMessengerStore } from '~/stores/messengerStore'
import { ERoutes } from '~/types/routes'
import { EConsents } from '~/types/usercentrics'

const isKioskMode = useState('kioskmode')

useHead({
  bodyAttrs: { class: isKioskMode.value ? 'body--kiosk' : '' }
})

const { $bus, $dataLayer } = useNuxtApp()
const vehicleSearchStore = useVehiclesSearchStore()
const settingsStore = useSettingsStore()
const formsStore = useFormsStore()

const { showSidepanel } = useSidepanel(ESidepanel.FORMS)
const route = useRoute()

useInitialiseCiamLogin()

// update isViewportDesktop
const onMatchMedia = () => {
  if (window.matchMedia) {
    const mq = window.matchMedia('(min-width: 992px)')

    mq.addEventListener('change', ({ matches }) => {
      settingsStore.updateViewport(matches)
    })

    const orientationMq = window.matchMedia('(orientation: portrait)')
    orientationMq.addEventListener('change', ({ matches }) => {
      settingsStore.updateOrientation(matches)
    })
  }

  settingsStore.updateViewport(window.innerWidth >= 992)
  settingsStore.updateOrientation(window.innerWidth < window.innerHeight)
}

// Scroll depth tracking
const scrollDepthDefaults = {
  0: false,
  25: false,
  50: false,
  75: false,
  100: false
}

const scrollDepth = ref({ ...scrollDepthDefaults })

const onScroll = () => {
  const windowHeight = window.innerHeight
  const documentHeight = document.documentElement.scrollHeight
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  const scrollPercentage = (scrollTop / (documentHeight - windowHeight)) * 100

  for (const key in scrollDepth.value) {
    const percentage = parseInt(key)

    if (scrollPercentage >= percentage && !scrollDepth.value[key]) {
      // Fire the event for the current scroll percentage.
      $dataLayer.scroll({ action: key })
      scrollDepth.value[key] = true
    }
  }
}

const debounceOnScroll = useDebounce(onScroll, 100)

watch(
  [() => vehicleSearchStore.isLoading, () => route.fullPath],
  () => {
    scrollDepth.value = { ...scrollDepthDefaults }
  },
  { immediate: true }
)

const { hideMessenger, disableNotification, enableNotification } =
  useMessengerStore()

onMounted(async () => {
  $bus.on('finance:intersecting', (isIntersecting) => {
    showChatBubble.value = !isIntersecting

    // Ensure the popup/notification is hidden when the finance calculator is in view
    if (isIntersecting) {
      hideMessenger()
      disableNotification()
    } else {
      enableNotification()
    }
  })

  window.addEventListener('scroll', debounceOnScroll)

  settingsStore.saveAgentUser(route.query.Agentuser)
  formsStore.updateMeta({ leadSource: document.referrer })

  await nextTick()

  onMatchMedia()

  if (route.query.valueMyCar) {
    formsStore.updateForm(EFormType.ValueMyCar)
    showSidepanel()
  }

  if (isKioskMode.value && route.name !== ERoutes.Homepage) {
    // If we have footer data stored in localstorage we udate in case the user dont land on homepage.
    const footerData = localStorage.getItem('footer')
    if (footerData) {
      settingsStore.updateFooter(JSON.parse(footerData))
    }
  }
})

onUnmounted(() => {
  $bus.off('finance:intersecting')

  window.removeEventListener('scroll', debounceOnScroll)
})

// Live Chat
const showChatBubble = ref(true)

// Manually track the page when
// user gives consent after
// the page has loaded
const { hasConsent, hasConsented } = useConsent(EConsents.GA)

watch(hasConsent, (newValue, oldValue) => {
  if (hasConsented(newValue, oldValue)) {
    $bus.emit('data-layer:page-view')
  }
})
</script>

<style lang="scss" scoped>
.default-layout {
  min-height: 100vh;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  // neeed to make it work on safari
  .main-container {
    min-height: calc(100vh - #{var(--site-header-height)});
    display: flex;

    & > main {
      height: inherit;
      width: 100%;

      &:deep(> *) {
        height: 100%;
      }
    }
  }
}
</style>
