<template>
  <client-only>
    <transition name="slideDown">
      <div
        v-if="notification"
        class="notificationBanner notificationBanner--notification"
      >
        <div class="notificationBanner__container">
          <div class="notificationBanner__text">
            <OsRichTextRenderer
              v-if="notificationCopy"
              name="notificationBanner"
              :content="notificationCopy"
            />
            <a
              v-if="notification.ctaCopy && notification.ctaCopy.length"
              :href="notification.ctaUrl"
              target="_blank"
              :title="notification.ctaSeo"
              rel="noopener"
            >
              {{ notification.ctaCopy }}
            </a>
          </div>
          <div class="notificationBanner__button">
            <OsButton
              :has-animation="false"
              :has-shadow="false"
              :theme="notification.closeCopy ? undefined : EButtonThemes.close"
              @click.prevent="bannerStore.hideBanner(notification.id)"
            >
              {{ notification.closeCopy }}
            </OsButton>
          </div>
        </div>
      </div>
    </transition>
  </client-only>
</template>

<script lang="ts">
import { EButtonThemes } from '~/components/base/Button.vue'
import { useBannersStore } from '~/stores/bannerStore'

export default {
  name: 'NotificationBanner',
  setup() {
    const { isViewportDesktop } = useUi()
    const bannerStore = useBannersStore()

    const bodySize = computed(() =>
      isViewportDesktop.value ? 'bodyCopyDesktop' : 'bodyCopyMobile'
    )
    const activeBanners = computed(() => bannerStore.getActiveBanners)

    // show only one banner
    const notification = computed(() => activeBanners.value[0])

    const notificationCopy = computed(() => {
      if (!notification.value) return
      if (notification.value[bodySize.value]) {
        return notification.value[bodySize.value]
      }

      return isViewportDesktop.value
        ? notification.value.bodyCopyMobile
        : notification.value.bodyCopyDesktop
    })

    return {
      EButtonThemes,
      notificationCopy,
      activeBanners,
      notification,
      bannerStore
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/tools/_typography.scss';
.notificationBanner {
  position: relative;
  z-index: 2;
  @include componentHorizontalPadding();

  &--notification {
    background-color: $grey--lightest;
  }

  &--promotion {
    background-color: $blue;

    .notificationBanner__text {
      color: $white;
      text-shadow: 0 0 2px $blue--dark;
    }

    .notificationBanner__button {
      .os-button {
        color: $white;
        text-shadow: 0 0 2px $blue--dark;

        polygon {
          fill: $white;
        }
      }
    }
  }
}

.notificationBanner__container {
  margin: 0 auto;
  padding-top: rem(24);
  padding-bottom: rem(27);
  width: 100%;
  max-width: 1440px;

  @include viewport('lg') {
    display: flex;
    padding-top: rem(16);
    padding-bottom: rem(16);
  }
}

.notificationBanner__text {
  width: 100%;
  font-family: $mb-font-text-bold;
  font-size: rem(16);
  line-height: 1.5;
  color: $black--light;
  margin-bottom: rem(27);

  p {
    white-space: pre-line;
  }

  a {
    @extend .inline-link !optional;
    @extend .bold !optional;
  }

  @include viewport('lg') {
    margin-right: rem(40);
    margin-bottom: 0;
  }
}

.notificationBanner__button {
  display: flex;
  align-items: center;

  .os-button {
    padding: 0;
    min-width: 0;
    height: auto;
    border: none;
    background-color: transparent;
    font-size: rem(16);
    font-family: $mb-font-text;
    cursor: pointer;
  }
}
</style>
