<template>
  <transition name="fade">
    <div
      v-if="isAvailable"
      class="os-mva__container"
      :class="{
        'os-mva--compact': isCompact,
        'os-mva--wall': isRetailerWall
      }"
    >
      <div class="os-mva" @click="toggle">
        <div
          v-if="messengerStore.notificationsNumber > 0"
          class="os-mva__badge"
        >
          {{ messengerStore.notificationsNumber }}
        </div>
        <div class="os-mva__icon">
          <OsIcon name="ChatBubble" class="text-white text-sm" />
        </div>
        <span v-if="!isCompact" class="os-mva__label text-sm">Live chat</span>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { useMessengerStore } from '~/stores/messengerStore'
import { ERoutes } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { useSettingsStore } from '~/stores/settingsStore'
import { ECondition } from '~/types/vehicle'

export default {
  name: 'ChatBubble',
  setup() {
    const { $dataLayer } = useNuxtApp()
    const route = useRoute()
    const messengerStore = useMessengerStore()
    const filtersStore = useFiltersStore()
    const settingsStore = useSettingsStore()
    const { isSidepanelOpen } = useSidepanels()

    const isCompact = computed<boolean>(
      () =>
        route.name === ERoutes.Vehicle ||
        route.name === ERoutes.Compare ||
        route.name === ERoutes.StockNotification ||
        isSidepanelOpen.value ||
        settingsStore.modes.finance ||
        route.name === ERoutes.Offer
    )

    const isRetailerWall = computed<boolean>(() => {
      const {
        active: { retailers, condition }
      } = filtersStore
      const isResultsPage = route.name === ERoutes.Results
      const isNew = condition === ECondition.New
      return isResultsPage && retailers.length < 1 && isNew
    })

    /**
     * Return true if the Widget is loaded and onlnie (isShown)
     */
    const isAvailable = computed<boolean>(
      () =>
        messengerStore.isLoaded &&
        !messengerStore.isShown &&
        messengerStore.isOnline
    )

    const toggle = () => {
      $dataLayer.linkClick({
        category: 'main',
        action: 'chat'
      })

      messengerStore.isShown
        ? messengerStore.hideMessenger()
        : messengerStore.showMessenger()
    }

    return {
      isCompact,
      isRetailerWall,
      isAvailable,
      toggle,
      messengerStore
    }
  }
}
</script>

<style lang="scss" scoped>
$height: 64px;
$mobileHeight: 32px;
$separationFromFooter: rem(24);

.os-mva__container {
  display: flex;
  float: right;
  position: sticky;
  bottom: calc(8px + env(safe-area-inset-bottom));
  margin-right: calc(8px + env(safe-area-inset-right));
  margin-bottom: rem(24);
  z-index: 33;
  transform: translateZ(1px);

  /*
   * we want this to be sticky so it stays on top of the Footer
   * but we also want to ignore it's space at the end of the page,
   * so this compensate that
   */
  margin-top: calc(-#{$height} - #{$separationFromFooter});

  &.os-mva--wall {
    bottom: calc(62px + env(safe-area-inset-bottom));

    @include viewport('md') {
      bottom: calc(20px + env(safe-area-inset-bottom));
    }
  }

  @include viewport('md', 'max-width') {
    margin-top: calc(-#{$mobileHeight} - #{$separationFromFooter});
    max-width: 114px;

    &.os-mva--compact {
      margin-right: 0;
      float: right;
      font-size: auto;
      .os-mva {
        width: rem(40);
        border-radius: rem(20) 0 0 rem(20);
        transition: all 0.2s ease-in-out;
        transform-origin: right;
        margin-right: 0;
      }

      .os-mva__badge {
        right: unset;
        left: -10px;
      }
    }
  }
}

.os-mva {
  margin-left: auto;
  cursor: pointer;
  user-select: none;
  height: $mobileHeight;
  padding-left: rem(8);
  padding-right: rem(8);
  display: flex;
  align-items: center;
  gap: rem(8);
  justify-content: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 8px 20px 0px;
  background: linear-gradient(0deg, $black--darkest 0%, $black--dark 100%);
  border-radius: rem(20);
  transition: all 0.2s ease-in-out;
  transform-origin: center;

  .os-mva__icon {
    width: 100%;
    height: 100%;
    display: contents;

    svg {
      width: 80%;
      height: 80%;
    }
  }

  .os-mva__label {
    color: $white;
    flex: 0 0 auto;
    padding-right: rem(12);
  }

  .os-mva__badge {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $red;
    color: $white;
    display: flex;
    font-size: 12px;
    border: solid $white 2px;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 0px 0px;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }

  @include viewport('md') {
    width: 64px;
    height: $height;

    .os-mva__label {
      display: none;
    }
  }
}
</style>
