<template>
  <div class="site-header">
    <header id="os-header" class="os-header">
      <nav>
        <div class="os-header__label text-lg">{{ retailer }}</div>
        <HeaderLogo />
        <div class="os-header__spacer"></div>

        <div id="os-header__qr"></div>
      </nav>
    </header>
  </div>
</template>

<script lang="ts">
import { useFiltersStore } from '~/stores/filtersStore'

export default {
  setup() {
    const filtersStore = useFiltersStore()

    const retailer = computed(
      () => filtersStore.active?.retailerName || 'Mercedes-Benz'
    )

    return {
      retailer
    }
  }
}
</script>

<style lang="scss">
$header-accent-height: 3px;

.site-header {
  position: sticky;
  top: 0;
  background-color: $black--darkest;
  color: $white;
  z-index: 14;

  header.os-header {
    position: relative;
    @include componentHorizontalPadding();

    nav {
      height: $headerHeight;
      display: flex;
      align-items: center;
      width: 100%;

      .header__logo {
        width: 32px;
        height: auto;
        margin: auto;
      }
      .os-header__label,
      .os-header__spacer {
        flex: 1 1 0px;
      }
    }
  }
}
</style>
