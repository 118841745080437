<template>
  <client-only>
    <footer v-if="footerData" id="os-footer" class="os-footer--kiosk">
      <div class="os-footer__card">
        <div class="os-footer__card__image" :style="backgroundImage">
          <h1>{{ footerData.message }}</h1>
        </div>
      </div>

      <div class="os-footer__logo">
        <OsLogo />
      </div>
    </footer>
  </client-only>
</template>

<script lang="ts">
import { useSettingsStore } from '~/stores/settingsStore'

export default {
  name: 'KioskFooter',

  setup() {
    const settingsStore = useSettingsStore()
    const footerData = computed(() => settingsStore.footer)
    const backgroundImage = computed(
      () => `background-image:url(${footerData.value?.image})`
    )
    return {
      footerData,
      backgroundImage
    }
  }
}
</script>

<style lang="scss" scoped>
.os-footer--kiosk {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  overflow: hidden;
  @include componentHorizontalPadding();

  .os-footer__card {
    padding-top: rem(88);
    border-top: 1px solid $grey--light;
    width: 100%;
    max-width: 1232px;

    .os-footer__card__image {
      width: 100%;
      background-image: url('/images/kiosk/kiosk_footer_AMG.jpg');
      background-size: cover;
      background-position: center;
      padding: rem(24);
      margin-top: rem(24);
      color: $white;
      @include aspect-ratio(1592, 775);

      h1 {
        max-width: 50%;
        font-size: rem(40);
      }
    }
  }

  .os-footer__logo {
    padding: 25vh 0px 25vh 18px;
  }
}
</style>
