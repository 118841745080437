<template>
  <picture>
    <source
      srcset="
        @/assets/images/logo.webp,
        @/assets/images/logo@2x.webp 2x,
        @/assets/images/logo@3x.webp 3x
      "
      type="image/webp"
    />
    <source
      srcset="
        @/assets/images/logo.png,
        @/assets/images/logo@2x.png 2x,
        @/assets/images/logo@3x.png 3x
      "
      type="image/png"
    />

    <img
      src="@/assets/images/logo@2x.png"
      alt="Mercedes-Benz Logo"
      width="18"
      height="18"
    />
  </picture>
</template>
<script>
export default {
  name: 'Logo'
}
</script>
