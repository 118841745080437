<template>
  <transition name="fade">
    <div v-if="showQRModel" class="os-qr-trap">
      <div class="os-qr" :class="{ 'os-qr--ready': isReady }">
        <div class="os-qr__canvas">
          <canvas ref="qrcanvastrap" />
          <div class="os-qr__label text-base bold">Continue on your phone</div>
        </div>
        <div class="os-qr__url text-base bold">{{ targetURL }}</div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import * as QRCode from 'qrcode'
import { IWindowOpenOptions } from '~/types/window'

export default {
  name: 'QRCodeTrap',
  setup() {
    const showQRModel = ref(false)
    const targetURL = ref('')
    const isReady = ref(false)
    const openedByBus = ref(false)
    const qrcanvastrap = ref<HTMLCanvasElement | any>()
    const { $dataLayer, $config, $bus } = useNuxtApp()

    // Hanlde click events
    const onclick = async (e: any) => {
      const href = e?.target?.href
      const target = e?.target.target || ''
      if (openedByBus.value) {
        openedByBus.value = false
        return false
      }

      isReady.value = false
      if (showQRModel.value || !href || !href.length) {
        showQRModel.value = false
        targetURL.value = ''
        openedByBus.value = false
        e.preventDefault()
        return false
      }
      // if (href !== undefined) {
      if (
        (href !== undefined && !href.startsWith($config.public.baseUrl)) ||
        target === '_blank'
      ) {
        e.preventDefault()
        showQRModel.value = true
        await nextTick()
        updateQRCode(href)
        return false
      }
      showQRModel.value = false
    }

    const trackQREvent = (event: String) => {
      $dataLayer.linkClick({
        category: `qr_code_${event}`,
        action: targetURL.value
      })
    }

    // updates the QR Code
    const updateQRCode = (url: string) => {
      targetURL.value = url
      QRCode.toCanvas(
        qrcanvastrap.value,
        url,
        {
          width: 200,
          margin: 4
        },
        function (error: any) {
          if (error) {
            trackQREvent('error')
            // eslint-disable-next-line no-console
            console.error(error)
          } else {
            trackQREvent('produced')
          }
          isReady.value = true
        }
      )
    }

    // handleURL opening from a bus event
    const handleURL = async (url: string) => {
      openedByBus.value = true
      if (url !== undefined) {
        showQRModel.value = true
        await nextTick()
        updateQRCode(url)
      }
      return false
    }

    onMounted(() => {
      window.addEventListener('click', onclick)
      $bus.on('qrcode:trap-link', (e: IWindowOpenOptions) => {
        handleURL(e.url)
      })
    })

    onUnmounted(() => {
      window.removeEventListener('click', onclick)
      $bus.off('qrcode:trap-link')
    })
    return {
      qrcanvastrap,
      isReady,
      showQRModel,
      targetURL
    }
  }
}
</script>
<style lang="scss" scoped>
.os-qr-trap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 56px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  z-index: 26;
  display: flex;
}
.os-qr {
  z-index: 6;
  opacity: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &--ready {
    animation-name: fadeIn;
    animation-delay: 0.5s;
    animation-duration: 0.6s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  .os-qr__canvas {
    color: $black--darkest;
    border-radius: rem(6);
    background-color: $white;
    overflow: hidden;
    align-self: center;
    height: fit-content;
    filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  }

  canvas {
    width: 164px;
    height: 164px;
    background: white;
  }
  .os-qr__label {
    max-width: 124px;
    text-align: center;
    margin: auto;
    padding-bottom: rem(12);
  }
}
.os-qr__url {
  text-align: center;
  margin-top: rem(12);
  padding: rem(12);
  background: white;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  border-radius: rem(6);
  word-wrap: break-word;
  max-width: 80vw;
}
</style>
